<template>
    <v-container fluid>
        <v-form @submit.prevent="getTracking('search')">
            <v-btn v-show="$vuetify.breakpoint.xsOnly"
                   @click="dialogAdd = true" color="indigo"
                   dark fab fixed top right
                   class="v-btn--add-form-top">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer"
                                v-slot="{ invalid, validated, passes, validate }">
                <v-card flat light>
                    <v-card-text class="pb-0">
                        <v-row>
                            <v-col cols="12" sm="10">
                                <h3 class="headline pb-2" color="black">
                                    {{ $t("search_tracking") }}
                                </h3>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly"
                                   cols="12" sm="2" class="text-right">
                                <v-btn color="primary" @click="dialogAdd = true">
                                    {{ $t("create") }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="9">
                                <ValidationProvider ref="tracking_name" name="tracking_name"
                                                    rules="min:1|max:255" v-slot="{ errors, valid }">
                                    <v-text-field v-model="tracking_name"
                                                  type="text" :error-messages="errors"
                                                  :disabled="loading"
                                                  prepend-icon="mdi-arrow-decision"
                                                  :label="$t('tracking_name')" outlined
                                                  rounded dense color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-select :value="perPage" :items="perPageItems"
                                          :disabled="loading" :label="$t('items_per_page')"
                                          prepend-icon="mdi-counter" outlined rounded dense
                                          @input="perPage = options.itemsPerPage = Number($event)">
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-col cols="12" class="d-flex justify-center justify-sm-end align-self-center">
                            <v-btn type="submit" :disabled="invalid || loading"
                                   :block="$vuetify.breakpoint.xsOnly" color="primary">
                                {{ $t("search") }}
                            </v-btn>
                        </v-col>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat light>
            <v-card-text class="px-0">
                <v-data-table :headers="filteredHeaders" :items="tracking"
                              :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalTracking"
                              :sort-by.sync="sortBy" :sort-desc.sync="sortDir"
                              :loading="loading" :locale="lang"
                              @page-count="pageCount = options.pageCount = Number($event)"
                              :loading-text="$t('loading_please_wait')"
                              class="elevation-1" hide-default-footer>
                    <template v-slot:item.created_at="{ item }">
                        {{ $moment(item.created_at).format("YYYY-MM-DD HH:MM") }}
                    </template>
                    <template v-slot:item.name="props">
                        <v-edit-dialog :return-value.sync="props.item.name"
                                       large persistent :disabled="props.item.name.length <= 3"
                                       @save="saveTracking(props.item)"
                                       :save-text="$t('save')" :cancel-text="$t('cancel')">
                            <v-icon class="mr-2">mdi-square-edit-outline</v-icon>
                            {{ props.item.name }}
                            <template v-slot:input>
                                <v-text-field v-model="props.item.name"
                                              :disabled="loading" :label="$t('tracking_name')"
                                              color="primary" clearable autofocus>
                                </v-text-field>
                            </template>
                        </v-edit-dialog>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="mr-2"
                                        @click="showTracking(item)"
                                        :title="$t('status_railways')">
                                    mdi-menu
                                </v-icon>
                            </template>
                            <span>{{ $t("tracking") }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" :disabled="item.deleted"
                                       @click="deleteTracking(item)" icon>
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ item.deleted ? $t("remotely") : $t("delete") }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-show="totalTracking > perPage" v-model="page"
                              :total-visible="totalVisiblePag" :length="pageCount" :disabled="loading">
                </v-pagination>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="dialogAdd" max-width="800" persistent eager>
            <v-card>
                <ValidationObserver ref="observerForm"
                                    v-slot="{ invalid, validated, passes, validate }">
                    <v-card-title>
                        <span class="headline">
                            {{ $t("tracking_creation") }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <ValidationProvider ref="tracking_name"
                                                    rules="required|min:1|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="tracking_name" type="text"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-folder-text-outline"
                                                  :label="$t('tracking_name')" color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="gray darken-1" class="ml-1 ml-sm-2" text
                               @click="closeDialogTracking">
                            {{ $t("cancel") }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text
                               :loading="loadingSaveTracking"
                               :disabled="invalid || loadingSaveTracking"
                               class="mr-1 mr-sm-2" @click="addTracking()">
                            {{ $t("save") }}
                        </v-btn>
                    </v-card-actions>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {mapGetters} from "vuex";
import debounce from "lodash/debounce";

export default {
    name: "Tracking",
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            sortBy: "id",
            sortDir: true,
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 5,
            totalTracking: 0,
            options: {},
            tracking_name: null,
            loading: false,
            tracking: [],
            dialogAdd: false,
            loadingSaveTracking: false,
            headers: [
                {
                    text: this.$t("name"),
                    align: "left",
                    sortable: true,
                    value: "name",
                },
                {
                    text: this.$t("date_created"),
                    align: "center",
                    sortable: false,
                    value: "created_at",
                    width: 200,
                },
                {
                    text: this.$t("actions"),
                    align: "center",
                    value: "action",
                    sortable: false,
                    width: 150,
                },
            ],
        };
    },
    computed: {
        ...mapGetters(["lang", "perPageItems"]),
        filteredHeaders() {
            return this.headers.filter((h) => !h.hide);
        },
    },
    watch: {
        options: {
            handler() {
                this.getTracking();
            },
            deep: false,
        },
    },
    mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage;
    },
    methods: {
        editTracking(item) {
            this.$router.push({
                name: "tracking.edit",
                params: {
                    id: item.id,
                },
            });
        },
        showTracking(item) {
            this.$router.push({
                name: "tracking_form",
                params: {
                    tracking_id: item.id,
                },
            });
        },
        async getTracking(type) {
            this.loading = true;
            const {sortBy, sortDesc, page, itemsPerPage} = this.options;
            let params = {};
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? "asc" : "desc";
            }
            if (page !== undefined) {
                params.page = page;
            }
            if (type === 'search') {
                params.page = 1
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }

            if (this.lang) {
                params.language = this.lang;
            }
            if (this.tracking_name) {
                params.tracking_name = this.tracking_name;
            }
            await this.$http
                .get("client/tracking", {
                    params: params,
                })
                .then((res) => {
                    this.tracking = res.body.data;
                    this.page = res.body.meta.current_page;
                    this.totalTracking = res.body.meta.total;
                    this.pageCount = res.body.meta.last_page;
                })
                .catch((err) => {
                    this.tracking = [];
                    this.totalTracking = 0;
                    this.$toastr.error(this.$t("failed_to_get_list_trackings"));
                })
                .finally((end) => {
                    this.loading = false;
                });
        },
        async deleteTracking(item) {
            if (confirm(this.$t("delete_tracking"))) {
                var _this = this;
                this.loading = true;
                await this.$http
                    .delete(`client/tracking/${item.id}`)
                    .then((res) => {
                        this.$toastr.success(
                            this.$t("tracking_has_been_deleted")
                        );
                        this.getTracking();
                    })
                    .catch((err) => {
                        this.$toastr.error(
                            this.$t("tracking_has_not_been_deleted")
                        );
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (
                                    hasOwnProperty.call(err.body.errors, prop)
                                ) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0],
                                        ]);
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message);
                            }
                        }
                    })
                    .finally((end) => {
                        this.loading = false;
                    });
            }
        },
        async saveTracking(item) {
            if (item.name.length < 3) {
                this.$toastr.error(this.$t("tracking_has_not_been_updated"));
                this.tracking = [];
                this.getTracking();
                return;
            }
            var _this = this;
            this.progress = 0;
            this.loading = true;
            var formData = new FormData();
            if (item.name) {
                formData.append("tracking_name", item.name);
            }
            if (this.lang) {
                formData.append("language", this.lang);
            }
            // Update
            await this.$http
                .put(`client/tracking/${item.id}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round(
                                (e.loaded / e.total) * 100
                            );
                        }
                    },
                })
                .then((res) => {
                    // console.log(res.data);
                    this.$toastr.success(this.$t("tracking_has_been_updated"));
                    //this.forceRerender();
                })
                .catch((err) => {
                    // console.log(err.data);
                    this.$toastr.error(
                        this.$t("tracking_has_not_been_updated")
                    );
                })
                .finally((end) => {
                    this.progress = 0;
                    this.loading = false;
                });
        },
        async addTracking(item) {
            if (this.tracking_name.length < 3) {
                this.$toastr.error(this.$t("tracking_has_not_been_updated"));
                return;
            }
            var _this = this;
            this.progress = 0;
            this.loading = true;
            var formData = new FormData();
            if (this.tracking_name) {
                formData.append("tracking_name", this.tracking_name);
            }

            // Add
            await this.$http
                .post(`client/tracking`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round(
                                (e.loaded / e.total) * 100
                            );
                        }
                    },
                })
                .then((res) => {
                    // console.log(res.data);
                    this.$toastr.success(this.$t("tracking_has_been_updated"));
                    this.closeDialogTracking();
                    this.getTracking();
                })
                .catch((err) => {
                    // console.log(err.data);
                    this.$toastr.error(
                        this.$t("tracking_has_not_been_updated")
                    );
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0],
                                    ]);
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message);
                        }
                    }
                })
                .finally((end) => {
                    this.progress = 0;
                    this.loading = false;
                });
        },
        closeDialogTracking() {
            this.dialogAdd = false;
            this.tracking_name = null;
        },
    },
};
</script>
